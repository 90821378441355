import React from 'react';
import styled from 'styled-components';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';

const FAQS = [
  {
    title: 'How should I look after my jewellery?',
    content: () => (
      <>
        Many factors can alter the appearance of your jewellery. Silver is a soft metal and over time it will naturally become worn and pick up marks. Polished silver will become more dull and a matt or textured finish will become shinier.
        <br />
    To minimise this I recommend the following:
    <br />
<ul>
<li>Avoid wearing your jewellery in chlorinated water such as in a swimming pool or at a spa.</li>
<li>Avoid spraying perfume or applying creams directly onto your jewellery.</li>
<li>Jewellery will tarnish when not being worn, so wear your treasures as much as possible. And when you are not wearing them, keep stored in a dark, dry place.</li>
</ul>
      </>
    ),
  },
  {
    title: 'How should I clean my jewellery?',
    content: () => (
      <>
        For smooth and shiny surfaces, gently rub with a polishing cloth. For rough surfaces or pieces with holes or bubbles clean using a tooth or nail brush and washing up liquid. You can also use a reputable silver ‘dip’ cleaning solution which works miracles. Do not use this if your jewellery contains precious stones or pearls.
      </>
    ),
  },
  {
    title: 'What ring size should I choose?',
    content: () => (
      <>
        This table shows the inside circumference of each UK ring size. But please know that it can be difficult to accurately measure your own finger, and this table is only a guide. For a truly accurate measurement I recommend either visiting a jeweller or purchasing an adjustable ring sizer online. Avoid measuring your fingers when you are cold. A ring should be able to slide over your knuckles and fit snug enough not to fall off but also allow for your fingers to expand when they are warm.

        <Table >
        <tr>
          <th>Ring Size UK</th>
          <th>Circumference (mm)</th>
          <th>Ring Size UK</th>
          <th>Circumference (mm)</th>
        </tr>
        <tr>
          <th>A</th>
          <th>37.8</th>
          <th>N</th>
          <th>53.8</th>
        </tr>
        <tr>
          <th>B</th>
          <th>39.1</th>
          <th>O</th>
          <th>55.1</th>
        </tr>
        <tr>
          <th>C</th>
          <th>40.4</th>
          <th>P</th>
          <th>56.3</th>
        </tr>
        <tr>
          <th>D</th>
          <th>41.7</th>
          <th>Q</th>
          <th>57.6</th>
        </tr>
        <tr>
          <th>E</th>
          <th>42.9</th>
          <th>R</th>
          <th>58.9</th>
        </tr>
        <tr>
          <th>F</th>
          <th>44.2</th>
          <th>S</th>
          <th>60.2</th>
        </tr>
          <tr>
          <th>G</th>
          <th>45.2</th>
          <th>T</th>
          <th>61.4</th>
        </tr>
        <tr>
          <th>H</th>
          <th>46.8</th>
          <th>U</th>
          <th>62.7</th>
        </tr>
        <tr>
          <th>I</th>
          <th>48.0</th>
          <th>V</th>
          <th>64.0</th>
        </tr>
        <tr>
          <th>J</th>
          <th>48.7</th>
          <th>W</th>
          <th>65.3</th>
        </tr>
        <tr>
          <th>K</th>
          <th>50.0</th>
          <th>X</th>
          <th>66.6</th>
        </tr>
        <tr>
          <th>L</th>
          <th>51.2</th>
          <th>Y</th>
          <th>67.8</th>
        </tr>
        <tr>
          <th>M</th>
          <th>52.5</th>
          <th>Z</th>
          <th>68.5</th>
        </tr>
      </Table>
      </>
    ),
  },

];

const Faq = () => (
    <Section id="FAQ">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Frequently Asked Questions</h1>
      <DropdownLists>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </DropdownLists>


    </Container>
  </Section>
);

const DropdownLists = styled.div`
  padding-bottom: 50px;
`;

const Table = styled.table`
border: 1px solid black;
border-collapse: collapse;
width:100%;
margin-top: 20px;
th, td {
  border: 1px solid black;
}
`;

export default Faq;

